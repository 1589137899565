function Impressum() {
    return (
      <div>
        <h1>Impressum:</h1>
        <b>Daniel Hörenkamp</b>
        Kreuzstraße 69
        44575 Castrop-Rauxel
        cressonline@web.de
      </div>
    );
  }
  
  export default Impressum;