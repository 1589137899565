import React, { useState } from 'react';
import axios from 'axios';
import '../css/register.css'; // Importiere die CSS-Datei

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const trimmedUsername = username.trim().toLowerCase();
        const trimmedEmail = email.trim().toLowerCase();
        const trimmedPassword = password.trim();

        try {
            const availabilityResponse = await axios.post('https://pokegodex.de/api/users/check-availability', { username: trimmedUsername, email: trimmedEmail });
            const { usernameAvailable, emailAvailable } = availabilityResponse.data;

            if (!usernameAvailable || !emailAvailable) {
                setError('Username oder Email sind bereits vergeben.');
                return;
            }

            await axios.post('https://pokegodex.de/api/users/registration', { username: trimmedUsername, email: trimmedEmail, password: trimmedPassword });
            alert('Registration successful');
        } catch (error) {
            setError('Registration failed');
            console.error('Error during registration:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="register-container">
            <form className="register-form" onSubmit={handleSubmit}>
                <h2>Registrierung</h2>
                <div className="form-group">
                    <label>Username</label>
                    <input 
                        type="text" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value.trim())} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value.trim())} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input 
                        type={passwordVisible ? "text" : "password"} 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value.trim())} 
                        required 
                    />
                    <button 
                        type="button" 
                        onMouseDown={togglePasswordVisibility} 
                        onMouseUp={togglePasswordVisibility} 
                        onMouseLeave={togglePasswordVisibility}
                        className="show-password-button"
                    >
                        Show
                    </button>
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input 
                        type={passwordVisible ? "text" : "password"} 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value.trim())} 
                        required 
                    />
                    <button 
                        type="button" 
                        onMouseDown={togglePasswordVisibility} 
                        onMouseUp={togglePasswordVisibility} 
                        onMouseLeave={togglePasswordVisibility}
                        className="show-password-button"
                    >
                        Show
                    </button>
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="register-button">Registrieren</button>
            </form>
        </div>
    );
};

export default Register;
