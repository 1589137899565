import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => Cookies.get('darkMode') !== 'false');

  useEffect(() => {
    Cookies.set('darkMode', darkMode, { expires: 365 });
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
