import React, { useContext } from 'react';
import '../css/playerprofile.css';

// Annahme: Du hast einen Context oder State für den Dark Mode
const DarkModeContext = React.createContext(false);

const Playerprofile = () => {
  const isDarkMode = useContext(DarkModeContext); // Nimm den Wert für Dark Mode aus dem Context

  return (
    <div className={`player-profile ${isDarkMode ? 'dark-mode' : ''}`} style={{ padding: '20px' }}>
      <h1>Dein Profil</h1>
      <p>Hier werden zukünftig Information hinterlegt werden können!</p>
    </div>
  );
};

export default Playerprofile;
