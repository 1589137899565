import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { DarkModeContext } from "../context/DarkModeContext";
import gotcha_own from "../images/gotcha.png";
import hundred_own from "../images/100_ball.png";
import shiny_own from "../images/shiny_ball.png";
import crypto_own from "../images/crypto_ball.png";
import canceled from "../images/cancel.png";
import checked from "../images/checked.png";
import "../css/pokedex.css";

const Pokedex = () => {
    const [pokemonData, setPokemonData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [visibleSections, setVisibleSections] = useState({ GEN1: true });
    const { darkMode } = useContext(DarkModeContext);
    const [userId, setUserId] = useState(null);

    const generations = ['GEN1', 'GEN2', 'GEN3', 'GEN4', 'GEN5', 'GEN6', 'GEN7', 'GEN8', 'GEN9'];

    useEffect(() => {
        const fetchUserId = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get('https://pokegodex.de/api/users/me', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserId(response.data.user_id);
            } catch (err) {
                console.error('Error fetching user ID:', err);
                setError('Error fetching user ID');
            }
        };

        fetchUserId();
    }, []);

    useEffect(() => {
        if (!userId) return;

        const fetchData = async (generation) => {
            try {
                const response = await axios.get(`https://pokegodex.de/api/pokedex?generation=${generation}&user_id=${userId}`);
                setPokemonData(prevState => ({
                    ...prevState,
                    [generation]: response.data
                }));
            } catch (err) {
                console.error(`Error fetching Pokédex data for ${generation}:`, err);
                setError(`Error fetching Pokédex data für ${generation}`);
            }
        };

        const fetchAllData = async () => {
            try {
                for (let gen of generations) {
                    await fetchData(gen);
                }
                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Error fetching data');
                setLoading(false);
            }
        };

        fetchAllData();
    }, [userId]); // Abhängigkeitsliste enthält userId, um sicherzustellen, dass Daten geladen werden, nachdem die userId gesetzt ist

    const toggleVisibility = (generation) => {
        setVisibleSections(prevState => ({
            ...prevState,
            [generation]: !prevState[generation]
        }));
    };

    const typeClass = (type) => {
        switch(type) {
            case 'Pflanze':
                return 'type-grass';
            case 'Gift':
                return 'type-poison';
            case 'Wasser':
                return 'type-water';
            case 'Boden':
                return 'type-ground';
            case 'Gestein':
                return 'type-stone';
            case 'Unlicht':
                return 'type-dark';
            case 'Fee':
                return 'type-fary';
            case 'Feuer':
                return 'type-fire';
            case 'Käfer':
                return 'type-bug';
            case 'Flug':
                return 'type-flying';
            // Weitere Typen können hier hinzugefügt werden
            default:
                return '';
        }
    };

    const updateOwnership = async (dexId, field) => {
        try {
            const allPokemonData = Object.values(pokemonData).flat();
            const existingEntry = allPokemonData.find(entry => entry.dex_id === dexId);

            if (!existingEntry) {
                console.error('No existing entry found for dexId:', dexId);
                return;
            }

            const updatedEntry = {
                user_id: userId,
                dex_id: dexId,
                catched: field === 'catched' ? !existingEntry.catched : (existingEntry.catched !== null ? existingEntry.catched : false),
                shiny: field === 'shiny' ? !existingEntry.shiny : (existingEntry.shiny !== null ? existingEntry.shiny : false),
                perfect: field === 'perfect' ? !existingEntry.perfect : (existingEntry.perfect !== null ? existingEntry.perfect : false),
                crypto: field === 'crypto' ? !existingEntry.crypto : (existingEntry.crypto !== null ? existingEntry.crypto : false),
            };

            console.log('Sending data:', updatedEntry);

            const response = await axios.post('https://pokegodex.de/api/pokemon_owned', updatedEntry);

            console.log('Update response:', response.data);

            setPokemonData(prevState => ({
                ...prevState,
                [existingEntry.generation]: prevState[existingEntry.generation].map(pokemon => 
                    pokemon.dex_id === dexId ? { ...pokemon, ...updatedEntry } : pokemon
                )
            }));
        } catch (err) {
            console.error('Error updating Pokemon ownership data:', err);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const renderPokemon = (pokemonData) => (
        <div className="table">
            {pokemonData.map(pokemon => (
                <div key={pokemon.dex_id} className="pokemon_container">
                    <div className="pokemon_name">
                        {pokemon.name}
                    </div>
                    <div className="pokemon_box">
                        <div className="pokemon_sprite">
                        <img src={pokemon.sprite_path} alt={pokemon.name} />
                        </div>
                    
                        <div className="pokemon_type">
                            <div className={typeClass(pokemon.first_type)}>
                                {pokemon.first_type}
                            </div>
                            <div className={typeClass(pokemon.second_type)}>
                                {pokemon.second_type}
                            </div>
                        </div>
                        <div className="pokemon_catched_table">
                            <div className="pokemon_catched_catched">
                            <img src={gotcha_own} alt="Gotcha"></img>
                            <div className={`catched ${pokemon.catched ? 'owned' : 'not-owned'}`}
                                onClick={() => updateOwnership(pokemon.dex_id, 'catched')}
                                style={{ display: 'inline-block', cursor: 'pointer' }}>
                                {pokemon.catched ? (<img src={checked} alt="Gefangen"/>) : (<img src={canceled} alt="Nicht gefangen" />)}
                            </div>
                            </div>
                            <div className="pokemon_catched_shiny">
                            <img src={shiny_own} alt="shiny_catched"></img>
                            <div className={`shiny ${pokemon.shiny ? 'owned' : 'not-owned'}`}
                                onClick={() => updateOwnership(pokemon.dex_id, 'shiny')}
                                style={{ display: 'inline-block', cursor: 'pointer' }}>
                                {pokemon.shiny ? (<img src={checked} alt="Gefangen"/>) : (<img src={canceled} alt="Nicht gefangen" />)}
                            </div>
                            </div>
                            <div className="pokemon_catched_hundred">
                            <img src={hundred_own} alt="hundred_catched"></img>
                            <div className={`perfect ${pokemon.shiny ? 'owned' : 'not-owned'}`}
                                onClick={() => updateOwnership(pokemon.dex_id, 'perfect')}
                                style={{ display: 'inline-block', cursor: 'pointer' }}>
                                {pokemon.perfect ? (<img src={checked} alt="Gefangen"/>) : (<img src={canceled} alt="Nicht gefangen" />)}
                            </div>
                            </div>
                            <div className="pokemon_catched_crypto">
                            <img src={crypto_own} alt="crypto_catched"></img>
                            <div className={`crypto ${pokemon.crypto ? 'owned' : 'not-owned'}`}
                                onClick={() => updateOwnership(pokemon.dex_id, 'crypto')}
                                style={{ display: 'inline-block', cursor: 'pointer' }}>
                                {pokemon.crypto ? (<img src={checked} alt="Gefangen"/>) : (<img src={canceled} alt="Nicht gefangen" />)}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="pokedex" style={{ backgroundColor: darkMode ? "#0D1B2A" : "white" }}>
            <h1 style={{ color: darkMode ? "white" : "black" }}>Pokédex</h1>
            {generations.map(generation => (
                <div key={generation}>
                    <h2 style={{ color: darkMode ? "white" : "black" }}>
                        {generation}
                        <button onClick={() => toggleVisibility(generation)}>
                            {visibleSections[generation] ? '▲' : '▼'}
                        </button>
                    </h2>
                    {visibleSections[generation] && renderPokemon(pokemonData[generation] || [])}
                </div>
            ))}
        </div>
    );
};

export default Pokedex;
