import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import '../css/friendcodes.css';

const FriendCodes = () => {
    const [friendcode, setFriendcode] = useState('');
    const [friendcodes, setFriendcodes] = useState([]);
    const [error, setError] = useState('');

    const fetchFriendcodes = async () => {
        try {
            const response = await axios.get('https://pokegodex.de/api/friendcodes');
            setFriendcodes(response.data);
        } catch (err) {
            console.error('Error fetching friendcodes:', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.post('https://pokegodex.de/api/friendcodes', { friendcode });
            setFriendcode('');
            fetchFriendcodes(); // Refresh the list
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setError('Der Code existiert bereits. Es kann alle 24 Stunden der selbe Code hinzugefügt werden');
            } else {
                console.error('Error submitting friendcode:', err);
                setError('An error occurred while submitting the friend code');
            }
        }
    };

    useEffect(() => {
        fetchFriendcodes();
    }, []);

    return (
        <div className="friendcodes-container">
            <form className="friendcodes-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={friendcode}
                    onChange={(e) => setFriendcode(e.target.value)}
                    placeholder="Freundescode eingeben"
                />
                <button type="submit">Absenden</button>
                {error && <p className="error-message">{error}</p>}
            </form>
            <ul className="friendcodes-list">
                {friendcodes.map((code) => (
                    <li className="entry" key={code.id}>
                        <div className="qrcode">
                            <QRCode value={code.friendcodes.toString()} size={64} />
                        </div>
                        <div className="entry-details">
                            <span>{code.friendcodes}</span>
                            <time>Hinzugefügt am: {new Date(code.insert_time).toLocaleString()}</time>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FriendCodes;
