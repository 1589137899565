import React, { useContext } from 'react'; // useContext hinzugefügt
import Navbar from './Navbar';
import { DarkModeProvider } from './context/DarkModeContext';
import { DarkModeContext } from "./context/DarkModeContext";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login/Login';
import Registration from './Login/Register';
import Footer from './Footer/Footer';
import Impressum from './Footer/Impressum';
import Arenamap from './Profile/Arenamap';
import Pokedex from './Profile/Pokedex';
import FriendCodes from './Global/Friendcodes';
import Playerprofile from './Profile/Playerprofile';
import Search from './Search/Search';
import './App.css';


function App() {
  return (
    <DarkModeProvider>
      <Router>
        <DarkModeApp />
      </Router>
    </DarkModeProvider>
  );
}

function DarkModeApp() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className="App">
      <header className="Navbar">
        <Navbar />
      </header>
      <main className="Body" style={{ backgroundColor: darkMode ? "#0D1B2A" : "white", padding: "15px 15px" }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/arenamap" element={<Arenamap />} />
          <Route path="/pokedex" element={<Pokedex />} />
          <Route path="/playerprofile" element={<Playerprofile />} />
          <Route path="/friendcodes" element={<FriendCodes />} />
          <Route path="/search" element={<Search />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
      <footer className="Footer">
        <Footer />
      </footer>
    </div>
  );
}

const Home = () => {
  const { darkMode } = useContext(DarkModeContext); // Verwende useContext innerhalb der Komponente

  return (
    <div className="Home">
      <div className="Information">
        <p style={{ color: darkMode ? "white" : "black" }}>
          Willkommen auf der Seite Pokegodex. Nach dem Login stehen folgende Funktionen zur Verfügung:
        </p>
        <ul style={{ color: darkMode ? "white" : "black" }}> 
          <li>Pokedex zum hinterlegen, welche Pokemon sich in welcher Form im Besitz befinden (Gefangen, Hunderter, Shiny, Crypto)</li>
          <li>Map auf der alle Orden hinterlegt werden können, die im Spiel auf gold gebracht worden sind</li>
          <li>Freundescodes zum finden neuer Freunde / Liste wird täglich geleert</li>
        </ul>
      </div>
      <div className="Lastchange">
        <h1>Letzte Änderungen</h1>
        <ul style={{ color: darkMode ? "white" : "black" }}>
          <li>Hinzufügen der Freundesliste</li>
          <li>Hinzufügen des Pokedex</li>
          <li>Hinzufügen der Arenamap</li>
        </ul>
      </div>
      <div className="plannedchanges">
        <h1>Geplante Änderungen</h1>
        <ul style={{ color: darkMode ? "white" : "black" }}>
          <li>Pokedex vervollständigen</li>
          <li>Profilinformationen anlegen</li>
          <li>Leistungsdaten am Profil hinterlegen</li>
          <li>Pokedexdaten in den Zwischenspeicher des Handys laden</li>
          <li>Fremde Profile aufrufen</li>
        </ul>
      </div>
    </div>
  );
};

export default App;
