import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import { DarkModeContext } from "../context/DarkModeContext";

function Footer() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? 'dark-theme-footer' : 'light-theme-footer'}>
      <div className={darkMode ? 'dark-theme' : 'light-theme'}>
        <Link to="/impressum">
          Impressum
        </Link>
        <Link to="/datenschutz">
          Datenschutzerklärung
        </Link>
      </div>
    </div>
  );
}

export default Footer;
