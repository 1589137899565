import React, { useContext } from "react";
import { DarkModeContext } from "./context/DarkModeContext";
import logo from "./images/luedo_logo.png";
import "./css/navbar.css";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
  const isLoggedIn = !!localStorage.getItem('token');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    alert('Logout successful');
    navigate('/login');
  };

  return (
    <div className="navbarWithLogo" id="navbarWithLogo">
      <div style={{ backgroundColor: darkMode ? "#0D1B2A" : "white", textAlign: "center", padding: "10px" }}
      >
      <a href="https://pokegodex.de">
        <img src={logo} alt="Logo" className="logo"/>
      </a>
      </div>

      <div className="navbarNavigation"
        style={{ backgroundColor: darkMode ? "#0D1B2A" : "white" }}
      >
        <div className="navbarItems">
          {/* all Elements no matter if the user is logged in or not */}
          {(isLoggedIn || !isLoggedIn) && (
            <>
              <Link
                to="/friendcodes"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                Freundescodes
              </Link>
            </>
          )}
          {isLoggedIn && (
            <>
              <Link
                to="/pokedex"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                Pokedex
              </Link>
              <Link
                to="/playerprofile"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                Mein Profil
              </Link>
              <Link
                to="/arenamap"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                Arenakarte
              </Link>
              <Link
                to="/search"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                Spielersuche
              </Link>
            </>
          )}
          {/* all Elements where the user is not logged in */}
          {!isLoggedIn && (
            <>
              <Link
                to="/login"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                Login
              </Link>
              <Link
                to="/registration"
                style={{
                  color: darkMode ? "white" : "#0D1B2A",
                  textDecoration: "none",
                }}
              >
                Registrieren
              </Link>
            </>
          )}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {isLoggedIn && (
            <button
              onClick={handleLogout}
              style={{
                marginRight: "10px",
                padding: "5px 10px",
                backgroundColor: darkMode ? "#0D1B2A" : "white",
                color: darkMode ? "white" : "#0D1B2A",
                border: "1px solid",
                borderRadius: "5px",
                cursor: "pointer"
              }}
            >
              Logout
            </button>
          )}
          <label className="switch" style={{ marginRight: "10px" }}>
            <input
              type="checkbox"
              onChange={toggleDarkMode}
              checked={darkMode}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
