import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import '../css/arenamap.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import seenIconUrl from '../images/ic_deploy_blue.png';
import bronzeIconUrl from '../images/ic_deploy_bronze.png';
import silverIconUrl from '../images/ic_deploy_silver.png';
import goldIconUrl from '../images/ic_deploy_gold.png';
import clickMarkerUrl from '../images/ic_deploy_marker.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
    popupAnchor: [0, -41]
});

L.Marker.prototype.options.icon = DefaultIcon;

let ClickIcon = L.icon({
    iconUrl: clickMarkerUrl,
    shadowUrl: iconShadow,
    iconAnchor: [40, 70],
    popupAnchor: [0, -110]
});

const getIconByType = (type) => {
    let iconUrl;
    switch (type) {
        case 'bronze':
            iconUrl = bronzeIconUrl;
            break;
        case 'silber':
            iconUrl = silverIconUrl;
            break;
        case 'gold':
            iconUrl = goldIconUrl;
            break;
        default:
            iconUrl = seenIconUrl;
            break;
    }
    return L.icon({
        iconUrl,
        shadowUrl: iconShadow,
        iconAnchor: [40, 70],
        popupAnchor: [0, -70]
    });
};

const ClickMarker = ({ onClick }) => {
    useMapEvents({
        click(e) {
            const target = e.originalEvent.target;

            // Überprüfe, ob der Klick nicht vom Button stammt
            if (!target.closest('.locate-button')) {
                onClick(e.latlng); // Nur dann den Marker setzen
            }
        }
    });
    return null;
};

const LocateButton = () => {
    const map = useMap();

    const handleLocate = (event) => {
        event.preventDefault();
        event.stopPropagation();

        // Fokus auf den Standort des Benutzers
        map.locate().on("locationfound", (e) => {
            map.flyTo(e.latlng, map.getZoom());
        });
    };

    return (
        <button
            className="locate-button"
            onClick={handleLocate}
            style={{
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                zIndex: 1000,
                padding: '10px',
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '5px',
                cursor: 'pointer'
            }}
        >
            Meine Position
        </button>
    );
};





const Arenamap = () => {
    const [clickedPosition, setClickedPosition] = useState(null);
    const [gyms, setGyms] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const [userId, setUserId] = useState(null);
    const [editingGym, setEditingGym] = useState(null);
    const [formState, setFormState] = useState({
        name: '',
        arenatype: 'bronze',
        note: ''
    });
    const [showModal, setShowModal] = useState(false);

    const position = [51.1657, 10.4515]; // Mitte von Deutschland

    useEffect(() => {
        fetchUserId();
    }, []);

    useEffect(() => {
        if (userId) {
            fetchGyms();
        }
    }, [userId]);

    const fetchUserId = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('https://pokegodex.de/api/users/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUserId(response.data.user_id);
        } catch (err) {
            console.error('Error fetching user ID:', err);
        }
    };

    const fetchGyms = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('https://pokegodex.de/api/gyms', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setGyms(response.data);
        } catch (err) {
            console.error('Error fetching gyms:', err);
        }
    };

    const handleMapClick = (latlng) => {
        setClickedPosition(latlng);
        setEditingGym(null);
        setFormState({ name: '', arenatype: 'bronze', note: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const gymData = {
            gymname: formState.name,
            type: formState.arenatype,
            longitude: clickedPosition ? clickedPosition.lng : editingGym.longitude,
            latitude: clickedPosition ? clickedPosition.lat : editingGym.latitude,
            note: formState.note,
            user_id: userId
        };

        try {
            const response = editingGym ? 
                await axios.put(`https://pokegodex.de/api/gyms/${editingGym.id}`, gymData, {
                    headers: { Authorization: `Bearer ${token}` }
                }) : 
                await axios.post('https://pokegodex.de/api/gyms', gymData, {
                    headers: { Authorization: `Bearer ${token}` }
                });

            if (editingGym) {
                setGyms(gyms.map(g => g.id === editingGym.id ? response.data : g));
            } else {
                setGyms([...gyms, response.data]);
            }

            setClickedPosition(null);
            setEditingGym(null);
            setFormState({ name: '', arenatype: 'bronze', note: '' });
            setShowModal(false);
        } catch (err) {
            console.error('Error submitting gym:', err);
        }
    };

    const handleLocate = (latlng) => {
        setUserPosition(latlng);
    };

    const handleDeleteGym = async (gymId) => {
        const token = localStorage.getItem('token');
        if (window.confirm('Möchten Sie diese Arena wirklich löschen?')) {
            try {
                await axios.delete(`https://pokegodex.de/api/gyms/${gymId}`, { headers: { Authorization: `Bearer ${token}` } });
                setGyms(gyms.filter(g => g.id !== gymId));
            } catch (err) {
                console.error('Error deleting gym:', err);
            }
        }
    };

    const handleEditGym = (gym) => {
        setEditingGym(gym);
        setFormState({
            name: gym.gymname,
            arenatype: gym.type,
            note: gym.note
        });
        setClickedPosition(null);
        setShowModal(true);
    };

    return (
        <div style={{ position: 'relative' }}>
            <MapContainer center={position} zoom={17} style={{ height: "67vh", width: "100%" }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {gyms.map((gym) => (
                    <Marker key={gym.id} position={[gym.latitude, gym.longitude]} icon={getIconByType(gym.type)}>
                        <Popup>
                            <div className="existingGymInfos">
                                Arena: {gym.gymname} <br /> Typ: {gym.type} <br /> Notiz: {gym.note}
                            </div>
                            <div className="existingGymButtons">
                                <button onClick={() => handleEditGym(gym)}>Bearbeiten</button>
                                <button onClick={() => handleDeleteGym(gym.id)}>Löschen</button>
                            </div>
                        </Popup>
                    </Marker>
                ))}
                {clickedPosition && !editingGym && (
                    <Marker position={clickedPosition} icon={ClickIcon}>
                        <Popup>
                            <form className="popup-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Name:</label>
                                    <input type="text" name="name" value={formState.name} onChange={handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>Arenatyp:</label>
                                    <select name="arenatype" value={formState.arenatype} onChange={handleInputChange}>
                                        <option value="seen">Gesehen</option>
                                        <option value="bronze">Bronze</option>
                                        <option value="silber">Silber</option>
                                        <option value="gold">Gold</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Notiz:</label>
                                    <input type="text" name="note" value={formState.note} onChange={handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <button type="submit">Absenden</button>
                                </div>
                            </form>
                        </Popup>
                    </Marker>
                )}
                {userPosition && (
                    <Marker position={userPosition} icon={DefaultIcon}>
                        <Popup>Ihr Standort</Popup>
                    </Marker>
                )}
                <ClickMarker onClick={handleMapClick} />
                <LocateButton onLocate={handleLocate} />
            </MapContainer>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>{editingGym ? 'Bearbeite Arena' : 'Neue Arena'}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Name:</label>
                                <input type="text" name="name" value={formState.name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>Arenatyp:</label>
                                <select name="arenatype" value={formState.arenatype} onChange={handleInputChange}>
                                    <option value="seen">Gesehen</option>
                                    <option value="bronze">Bronze</option>
                                    <option value="silber">Silber</option>
                                    <option value="gold">Gold</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Notiz:</label>
                                <input type="text" name="note" value={formState.note} onChange={handleInputChange} />
                            </div>
                            <div className="editExistingGymButtons">
                                <button type="submit">{editingGym ? 'Speichern' : 'Absenden'}</button>
                                <button type="button" onClick={() => setShowModal(false)}>Abbrechen</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Arenamap;
